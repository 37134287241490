<template>
  <transition name="fade">
    <div v-if="isActive" class="tab text-sans text-above-md leading-sm">
      <slot></slot>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'ProductTab',
  data() {
    return {
      isActive: false
    }
  },
  computed: {
    href() {
      return '#' + this.name.toLowerCase().replace(/ /g, '-');
    }
  },
  props: {
    name: {
      required: true
    },
    selected: {
      default: false
    },
    text: {
      default: true
    }
  },
  mounted() {
    this.isActive = this.selected;
  }
}
</script>
<style lang="scss" scoped>
  .fade-enter-active, .fade-leave-active {
    opacity: 1;
    filter: alpha(opacity=100);
    z-index: 1;
  }

  .fade-enter, .fade-leave-to {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0;
  }

  .tab {
    transition: opacity 1s ease;
  }
</style>
