<template>
  <no-ssr>
    <div class="main">
      <article class="product__section--top relative shadow-outline">
        <div class="container mx-auto lg:px-17">
          <div class="flex hidden lg:block">
            <div class="w-full">
              <breadcrumbs :routes="productBreadcrumbs" :active-route="product.name"/>
            </div>
          </div>
          <div class="lg:hidden block pt-xl pb-2">
            <button-outline
              @click.native="$router.go(-1)"
              :color="'primary'"
              class="back-btn"
            >
              <i class="ss-gizmo back-icon ss-left text-h5 leading-none inline-block"></i>
              <span class="text-h5">
                {{ $t('Back') }}
              </span>
            </button-outline>
          </div>
          <div class="lg:flex pb-13">
            <div class="w-full lg:w-2/3 lg:pr-15">
              <div class="product__card relative overflow-hidden pb-10 md:flex lg:block lg:pt-12">
                <div class="w-full md:w-1/2 relative z-20">
                  <p class="product__brand lg:my-1 lg:leading-md text-base tracking-md leading-sm">
                    {{ getBrandName | htmlDecode }}
                  </p>
                  <p class="product__name font-black lg:text-heading font-serif lg:leading-md text-mid leading-h3">
                    {{ product.name | htmlDecode }}
                  </p>
                  <div v-if="product.type_id !== 'grouped' && product.type_id !== 'bundle'">
                    <p
                      v-if="product.sizing_oz"
                      class="product__label text-md leading-h5 text-above-md tracking-xs uppercase"
                    >
                      {{ `${product.sizing_oz} Oz` }}
                    </p>
                    <p
                      v-if="product.sku"
                      class="product__sku text-grey-4 text-h5 uppercase block">
                      {{ $t('SKU:') }} {{ product.sku }}
                    </p>
                  </div>
                  <div v-else>
                    <p v-if="selectedProduct.option">
                      {{ selectedProduct.option }}
                    </p>
                    <p
                      v-if="selectedProduct.sizing_oz"
                      class="product__label text-md leading-h5 text-above-md tracking-xs uppercase"
                    >
                      {{ `${selectedProduct.sizing_oz} Oz` }}
                    </p>
                    <p
                      v-if="selectedProduct.sku"
                      class="product__sku text-grey-4 text-h5 uppercase block">
                      {{ $t('SKU:') }} {{ selectedProduct.sku }}
                    </p>
                  </div>
                </div>
                <div class="w-full md:w-1/2">
                  <div v-if="image"
                       class="product__image items-end justify-center"
                  >
                    <img
                      :src="image.src"
                      :alt="product.name"
                      :style="{ paddingTop: imageIndent }"
                    >
                  </div>
                  <div v-else
                       class="product__image items-end justify-center"
                  >
                    <img
                      :src="imgPlaceholder"
                      :alt="product.name"
                      :style="{ paddingTop: imageIndent }"
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full lg:w-1/3 lg:pl-10 lg:pt-20" v-observe-visibility="topQtyVisibilityChanged">
              <div
                v-if="product.type_id !== 'grouped' && product.type_id !== 'bundle'"
                class="product__price-box flex mb-6"
              >
                <div class="w-1/2">
                  <div class="product__price">
                    <p class="text-h6 uppercase text-dark leading-thick">
                      {{ $t('Price') }}
                    </p>
                    <div class="font-normal text-heading-page italic leading-h5 font-serif mt-xs pr-10">
                      <div v-show="!rulePrice">
                        <div v-if="product.special_price && product.priceInclTax && product.originalPriceInclTax">
                          <span>{{ product.priceInclTax | price }}</span>&nbsp;
                          <span>{{ product.originalPriceInclTax | price }}</span>
                        </div>
                        <div v-if="!product.special_price && product.priceInclTax">
                          {{ product.qty > 0 ? product.priceInclTax : product.priceInclTax | price }}
                        </div>
                      </div>
                      <div v-show="rulePrice">
                        {{ rulePrice | price }}
                      </div>
                      <div v-if="rewardSpendEligible">
                        <div class="italic text-md">or {{ getPointPrice }} Points</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-1/2 flex items-center flex-col justify-center">
                  <div class="product__availible text-average leading-none pl-md">
                    {{ $t('Available for purchase in increments of ') + incrementStep }}
                  </div>
                </div>
              </div>
              <earn-points
                v-if="product.type_id !== 'grouped' && product.type_id !== 'bundle'"
                :product="product"
                :qty="product.qty"
              />
              <div
                v-if="(product.type_id === 'grouped' || product.type_id === 'bundle') && !loading && productLinks.length"
                class="product__grouped"
              >
                <product-links :product="product" :products="productLinks" :is-product-page="true"/>
              </div>
              <div
                class="product__options"
                v-if="dynamicProductTypes.includes(product.type_id) && !loading && (!product.errors || Object.keys(product.errors).length === 0) && Object.keys(configuration).length > 0"
              >
                <div
                  class="w-full mb-2"
                  v-for="(option, index) in product.configurable_options"
                  :key="index"
                >
                  <div class="row top-xs m0 pt15 pb40 variants-wrapper">
                    <div v-if="['shade', 'color'].includes((option.label || '').toString().toLowerCase()) && attributesByCode && option.attribute_code">
                      <div class="variants-label uppercase text-black text-h6 tracking-md" data-testid="variantsLabel">
                        <div>{{ option.label }}:</div>
                        <span class="weight-700 text-average" v-if="!colorHoverLabel">
                          {{ configuration[option.attribute_code ? option.attribute_code : option.label.toLowerCase()].label }}
                        </span>
                        <span class="weight-700 text-average" v-else>
                          {{ colorHoverLabel }}
                        </span>
                      </div>
                      <div v-for="(group, gIndex) in getConfigurableProductGroupingsMapping(option.attribute_code, product)" :key="group.key">
                        <template v-if="Object.keys(group.items || {}).length">
                          <div class="inline-flex relative uppercase text-base color-option-title" v-if="group.name"><strong>{{ group.name}}</strong></div>
                          <div class="inline-flex flex-wrap">
                            <color-selector
                              v-for="(c, i) in group.items"
                              :key="i" :id="c.id"
                              :label="c.label"
                              context="product"
                              :products="product.configurable_children"
                              :group-index="gIndex + i"
                              :code="option.attribute_code"
                              @hover="onColorHover"
                              @hoverout="onColorHover(false)"
                            />
                          </div>
                        </template>
                      </div>

                    </div>
                    <div class="sizes" v-else-if="option.label === 'Size'">
                      <swatch-select
                        class="col-xs-12 col-md-6 mb10"
                        :name="option.attribute_code"
                        :options="options[option.attribute_code]"
                        :selected="configuration[option.attribute_code].id"
                        @change="switchFilter(option.id, option.label)"
                      >
                        {{ option.label }}
                      </swatch-select>
                    </div>
                    <div
                      v-else
                      :class="option.attribute_code"
                    >
                      <div class="variants-label mb-2" data-testid="variantsLabel">
                        {{ option.label }}:
                        <span class="weight-700">
                          {{
                            configuration[option.attribute_code ? option.attribute_code : option.label.toLowerCase()].label
                          }}
                        </span>
                      </div>
                      <div class="flex justify">
                        <generic-selector
                          v-for="(s, i) in options[option.attribute_code]"
                          :key="i" :id="s.id"
                          :label="s.label"
                          context="product"
                          :code="option.attribute_code" class="mr-2"
                          :class="{ active: s.id == configuration[option.attribute_code].id }"
                          v-focus-clean
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="product__qty">
                <div
                  v-if="product.stock && product.stock.is_in_stock || product.type_id === 'grouped' && product.type_id === 'bundle'">
                  <div class="w-full mb-sm" v-if="product.type_id !== 'grouped' && product.type_id !== 'bundle'">
                    <base-input-number
                      :min="incrementStep"
                      :name="$t('Quantity')"
                      class="quantity-select"
                      :increment="incrementStep"
                      @blur="$v.$touch()"
                      :validations="[
                      {
                        condition: $v.product.qty.$error && !$v.product.qty.minValue,
                        text: $t('Quantity must be above 0')
                      },
                      {
                        condition: $v.product.qty.isValueMultiple && incrementStep !== 1,
                        text: $t('Quantity must be multiple of ') + incrementStep
                      }
                    ]"
                      v-model="product.qty"
                    >
                      {{ $t('Qty') }}
                    </base-input-number>
                  </div>
                  <button-with-icon
                    v-if="rewardSpendEligible"
                    @click.native="addToRewardsCart"
                    :disabled="!isInStock || loading || OfflineOnly"
                    :loading="loading"
                    :color="`primary2`"
                    class="popup-button flex-grow pr-5 pl-10 w-full">
                    <span v-if="OnlineOnly">
                      {{ $t('Buy with') }} {{ totalPointsPrice }} {{ $t('points') }}
                    </span>
                    <span v-else>
                      {{ $t('You are offline.') }}
                    </span>
                  </button-with-icon>
                  <div v-if="rewardSpendEligible" class="text-md px-2 pl-10">Available Balance: {{ currentBalance }} Points</div>
                  <add-to-cart
                    v-if="product.type_id !== 'grouped' && product.type_id !== 'bundle'"
                    :disabled="$v.product.qty.isValueMultiple || $v.product.qty.$error && !$v.product.qty.minValue"
                    :product="product"
                    :category-id="category.id"
                    :is-product-page="true"
                    class="w-full mt-3"
                  >{{ $t('Buy with') }} {{ getTotalPrice | price }}</add-to-cart>

                </div>
                <div v-else>
                  <back-in-stock
                    v-show="product.type_id !== 'grouped' && product.type_id !== 'bundle'"
                    :product="product"
                    :is-product-page="true"
                  />
                </div>
              </div>
              <div class="product__info mb-md pt-3" v-if="isBundleDisplay && product.description">
                <p class="product__desc text-above-md leading-sm lg:pt-10 mb-md" v-html="product.description" />
              </div>
            </div>
          </div>
          <div class="product__info mb-md" v-if="!isBundleDisplay && product.description">
            <p class="product__desc text-above-md leading-sm mt-3 mb-md pb-10 lg:w-4/5 w-full m-auto" v-html="product.description"/>
          </div>
        </div>

        <Transition>
        <div class="product__qty fixed p-5 bottom-0 bg-white md:hidden" style="z-index:10000;" v-if="lowerQtyVisible">
          <div
            v-if="product.stock && product.stock.is_in_stock || product.type_id === 'grouped' && product.type_id === 'bundle'">
            <div class="w-full mb-sm" v-if="product.type_id !== 'grouped' && product.type_id !== 'bundle'">
              <base-input-number
                :min="incrementStep"
                :name="$t('Quantity')"
                class="quantity-select"
                :increment="incrementStep"
                @blur="$v.$touch()"
                :validations="[
                {
                  condition: $v.product.qty.$error && !$v.product.qty.minValue,
                  text: $t('Quantity must be above 0')
                },
                {
                  condition: $v.product.qty.isValueMultiple && incrementStep !== 1,
                  text: $t('Quantity must be multiple of ') + incrementStep
                }
              ]"
                v-model="product.qty"
              >
                {{ $t('Qty') }}
              </base-input-number>
            </div>
            <button-with-icon
              v-if="rewardSpendEligible"
              @click.native="addToRewardsCartWrapper"
              :disabled="!isInStock || loading || OfflineOnly"
              :loading="loading"
              :color="`primary2`"
              class="popup-button flex-grow pr-5 pl-10 w-full">
              <span v-if="OnlineOnly">
                {{ $t('Buy with') }} {{ totalPointsPrice }} {{ $t('points') }}
              </span>
              <span v-else>
                {{ $t('You are offline.') }}
              </span>
            </button-with-icon>
            <div v-if="rewardSpendEligible" class="text-md px-2 pl-10">Available Balance: {{ currentBalance }} Points</div>
            <add-to-cart
              v-if="product.type_id !== 'grouped' && product.type_id !== 'bundle'"
              :disabled="$v.product.qty.isValueMultiple || $v.product.qty.$error && !$v.product.qty.minValue"
              :product="product"
              :category-id="category.id"
              :is-product-page="true"
              class="w-full mt-3"
            >{{ $t('Buy with') }} {{ getTotalPrice | price }}</add-to-cart>

          </div>
          <div v-else>
            <back-in-stock
              v-show="product.type_id !== 'grouped' && product.type_id !== 'bundle'"
              :product="product"
              is-product-page
            />
          </div>
        </div>
        </Transition>
      </article>
      <section class="product__tabs">
        <div
          v-if="product.ingredients_long || product.how_it_works"
          class="container mx-auto py-10 product__tabs-container"
        >
          <tabs>
            <tab
              :name="$t('Ingredients')"
              v-if="product.ingredients_long"
              :selected="!!product.ingredients_long"
              :text="product.ingredients_long"
            >
              <p v-html="product.ingredients_long"></p>
            </tab>
            <tab
              :name="$t('How it works')"
              v-if="product.how_it_works"
              :text="product.how_it_works"
            >
              <p v-html="product.how_it_works"/>
            </tab>
          </tabs>
        </div>
      </section>
      <related-products
        v-if="product.type_id !== 'grouped' && product.type_id !== 'bundle'"
        type="upsell"
        :heading="$t('YOU MIGHT ALSO LIKE')"
        :category-id="category.id || ''"
        class="lg:px-17 py-xl px-sm"
      />
    </div>
  </no-ssr>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import {minValue} from 'vuelidate/lib/validators'
import Product from 'theme/mixins/pages/Product'
import Breadcrumbs from 'theme/components/core/Breadcrumbs'
import AddToCart from 'theme/components/core/blocks/Product/AddToCart'
import EarnPoints from 'theme/components/theme/EarnPoints'
import BaseInputNumber from 'theme/components/core/blocks/Product/BaseInputNumber'
import VueOfflineMixin from 'vue-offline/mixin'
import focusClean from 'theme/components/theme/directives/focusClean'
import GenericSelector from 'theme/components/core/GenericSelector'
import ColorSelector from 'theme/components/core/ColorSelector'
// import SizeSelector from 'theme/components/core/SizeSelector'
import NoSSR from 'vue-no-ssr'
import SwatchSelect from 'theme/components/core/blocks/Form/SwatchSelect'
import ProductLinks from 'theme/components/core/ProductLinks'
import Tabs from 'theme/components/core/blocks/Product/Tabs'
import Tab from 'theme/components/core/blocks/Product/Tab'
import RelatedProducts from 'theme/components/core/blocks/Product/Related'
import ButtonOutline from 'theme/components/theme/ButtonOutline'
import BackInStock from 'theme/components/theme/BackInStock'
import ProductImageScaling from 'theme/mixins/blocks/ProductImageScaling'
import PriceRules from 'theme/mixins/blocks/PriceRules'
import {getProductQtyIncrement} from 'theme/helpers/getProductQtyIncrement'
import config from 'config'
import i18n from '@vue-storefront/i18n'
import ButtonWithIcon from 'theme/components/theme/ButtonWithIcon'
import EarnedPoints from 'theme/mixins/blocks/EarnedPoints'
import { Logger } from '@vue-storefront/core/lib/logger'
import { getThumbnailPath } from '@vue-storefront/core/helpers'
import debounce from 'lodash-es/debounce'

const isValueMultiple = (step) => (value) => value % step !== 0

export default {
  name: 'Product',
  components: {
    Breadcrumbs,
    AddToCart,
    EarnPoints,
    BaseInputNumber,
    // SizeSelector,
    ColorSelector,
    GenericSelector,
    SwatchSelect,
    ProductLinks,
    Tabs,
    Tab,
    RelatedProducts,
    ButtonOutline,
    BackInStock,
    ButtonWithIcon,
    'no-ssr': NoSSR
  },
  mixins: [
    Product,
    VueOfflineMixin,
    ProductImageScaling,
    PriceRules,
    EarnedPoints
  ],
  directives: {
    focusClean
  },
  data () {
    return {
      incrementStep: 1,
      selectedProduct: {},
      imgPlaceholder: config.images.productPlaceholder,
      desktopPoint: 1024,
      isMobile: false,
      windowWidth: null,
      currentBalance: 0,
      colorHoverLabel: false,
      lowerQtyVisible: false
    }
  },
  computed: {
    ...mapState({
      categoriesList: state => state.category.list
    }),
    ...mapGetters({
      breadcrumbs: 'product/breadcrumbs'
    }),
    dynamicProductTypes () {
      // temp fix for disappearing color swatch selectors
      // after adding color variant to cart, this.product is set to "simple" product type which fails v-if of swatch selector container,
      // so I copied this color swatch presence test here so we aren't including "simple" product type in the color swatch container
      // test anywhere but on a color page. seems to work fine...?
      if (this.product.configurable_options && this.product.configurable_options.length > 0 && ['shade', 'color'].includes(this.product.configurable_options[0].label || '').toString().toLowerCase() && this.attributesByCode && this.product.configurable_options[0].attribute_code) {
        return ['configurable', 'virtual', 'simple']
      } else {
        return ['configurable', 'virtual']
      }
    },
    isBundleDisplay () {
      let name = (this.product.name || '').toLowerCase()
      return this.product.description_position_alt || this.product.type_id === 'grouped' || name.includes('intro') || name.includes('bundle')
    },
    getTotalPrice () {
      let rulePrice = this.getRulePrice(this.product)

      if (rulePrice) {
        return rulePrice * this.product.qty
      }
      return this.product.priceInclTax * this.product.qty
    },
    getPointPrice () {
      let isFloat = (n) => Number(n) === n && n % 1 !== 0
      let rulePrice = this.getRulePrice(this.product)
      let price = rulePrice || this.product.point_redemption_price
      return isFloat(price) ? price.toFixed(2) : price
    },
    totalPointsPrice () {
      let rulePrice = this.getRulePrice(this.product)
      let price = (this.product.point_redemption_price * (this.product.qty ? this.product.qty : this.incrementStep)).toFixed(2)

      if (rulePrice) price = (rulePrice * (this.product.qty ? this.product.qty : this.incrementStep)).toFixed(2)
      return price
    },
    rewardSpendEligible () {
      return this.product.point_can_spend
    },
    productBreadcrumbs () {
      let breadcrumbs = this.breadcrumbs.routes
      let defaultBreadcrumbs = [
        {
          name: 'Home',
          route_link: '/'
        }
      ]
      let categoryBreadcrumbs = breadcrumbs ? breadcrumbs[breadcrumbs.length - 2] : {}

      if (categoryBreadcrumbs) {
        defaultBreadcrumbs.push({
          name: categoryBreadcrumbs.name,
          route_link: categoryBreadcrumbs.route_link
        })
      }

      return defaultBreadcrumbs
    },
    getBrandName () {
      let category = this.categoriesList.filter(item => this.category.parent_id === item.id && item.level === 3)

      return category.length ? category[0]['name'] : this.category.name
    },
    productLinks () {
      let productLinks = this.product.type_id === 'grouped' ? this.product['product_links'] : this.product['bundle_options'][0]['product_links']
      return productLinks || []
    },
    rulePrice () {
      return this.getRulePrice(this.product)
    },
    imageIndent () {
      let indents = config.entities.category.imageIndent || {}

      return indents && indents[this.product['packaging_type']] ? `${(this.isMobile ? 400 : 600) * (indents[this.product['packaging_type']] / 100)}px` : 0
    },
    isInStock () {
      return this.product.stock ? this.product.stock.is_in_stock : false
    }
  },
  methods: {
    async updateAvailableBalance () {
      this.currentBalance = await this.getCurrentBalance(this.product.brand_id)
    },
    topQtyVisibilityChanged (ev) {
      this.lowerQtyVisible = !ev
    },
    onColorHover (event) {
      this.colorHoverLabel = event ? event.label : false
    },
    notifyUser (notificationData) {
      this.$store.dispatch('notification/spawnNotification', notificationData)
    },
    selectedNewProduct (product) {
      this.selectedProduct = product
    },
    checkIsMobile () {
      this.windowWidth = document.documentElement.clientWidth
      this.isMobile = this.windowWidth <= this.desktopPoint
    },
    addToRewardsCartWrapper: debounce(function () {
      this.addToRewardsCart()
    }, 500, {
      leading: true,
      trailing: false
    }),
    async addToRewardsCart () {
      this.loading = true

      try {
        const diffLog = await this.$store.dispatch('cart/upsertLoyaltyItem', {
          product: this.product,
          qty: this.product.qty,
          upsert: false // we want to update on old qty
        })

        if (diffLog) {
          let hasError = false

          if (diffLog.clientNotifications && diffLog.clientNotifications.length > 0) {
            diffLog.clientNotifications.forEach(notificationData => {
              if (notificationData.type === 'error') {
                hasError = true
              }
              this.notifyUser({
                type: notificationData.type,
                message: notificationData.message,
                action1: {label: i18n.t('OK')},
                action2: {
                  label: i18n.t('Proceed to checkout'),
                  action: () => {
                    this.$router.push(this.localizedRoute('/cart'))
                  }
                }
              })
            })
          } else if (diffLog.serverResponses && diffLog.serverResponses.length > 0) {
            diffLog.serverResponses.forEach(notificationData => {
              if (notificationData.result.resultCode === 400) {
                hasError = true
                this.notifyUser({
                  type: 'error',
                  message: notificationData.result.result,
                  action1: {label: i18n.t('OK')},
                  action2: null
                })
              }
            })
          }

          if (!hasError) {
            // this.$store.dispatch('loyalty/addRewardItem', { productToAdd: this.product })
            this.$bus.$emit('modal-hide', 'modal-points-quick-view')
          }
        } else {
          this.notifyUser({
            type: 'success',
            message: i18n.$t('Product has been added to the cart!'),
            action1: {label: i18n.t('OK')},
            action2: null
          })
          // this.$store.dispatch('loyalty/addRewardItem', { productToAdd: this.product })
          this.$bus.$emit('modal-hide', 'modal-points-quick-view')
        }
        this.loading = false
        this.$bus.$emit('after-product-added', {})
        this.$bus.$emit(`cart_${this.product.sku}_updated`)
        return diffLog
      } catch (err) {
        this.notifyUser({
          type: 'error',
          message: err || 'An error occurred....',
          action1: {label: i18n.t('OK')}
        })
        this.$bus.$emit(`cart_${this.product.sku}_updated`)
        this.loading = false
        return null
      }
    },
    onAfterFilterChanged (filterOption) {
      this.configurableProductLabel = filterOption.label
      this.$bus.$emit('product-before-configure', { filterOption: filterOption, configuration: this.configuration })
      const prevOption = this.configuration[filterOption.attribute_code]

      this.configuration[filterOption.attribute_code] = filterOption
      this.$forceUpdate() // this is to update the available options regarding current selection
      this.$store.dispatch('product/configure', {
        product: this.product,
        configuration: this.configuration,
        selectDefaultVariant: true,
        fallbackToDefaultWhenNoAvailable: false,
        setProductErorrs: true
      }).then((selectedVariant) => {
        if (config.products.setFirstVarianAsDefaultInURL) {
          this.$router.push({ params: { childSku: selectedVariant.sku } })
        }
        if (!selectedVariant) {
          if (typeof prevOption !== 'undefined' && prevOption) {
            this.configuration[filterOption.attribute_code] = prevOption
          } else {
            delete this.configuration[filterOption.attribute_code]
          }
          this.notifyWrongAttributes()
        }
      }).catch(err => Logger.error({
        info: 'Dispatch product/configure in Product.vue',
        err
      }))
    }
  },
  mounted () {
    this.checkIsMobile()
    this.$nextTick(async () => {
      await this.updateAvailableBalance()
      if (!this.$isServer) {
        await new Promise(resolve => setTimeout(resolve, 1000))

        if (this.currentBalance === 0) {
          this.updateAvailableBalance()
        }
      }

      this.$bus.$on('cart-after-itemchanged', this.updateAvailableBalance)
    })
  },
  beforeMount () {
    this.incrementStep = getProductQtyIncrement(this.product)
    this.$bus.$on('selected-new-product', this.selectedNewProduct)
  },
  beforeDestroy () {
    if (!this.isMounted) return

    this.$bus.$off('selected-new-product', this.selectedNewProduct)
    this.$bus.$off('cart-after-itemchanged', this.updateAvailableBalance)
  },
  validations () {
    return {
      product: {
        qty: {
          minValue: minValue(1),
          isValueMultiple: isValueMultiple(this.incrementStep)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$color-black: #000;

.v-leave-active, .v-enter-active {
  transition: opacity 0.3s ease;
}

.v-enter-from, .v-leave-to {
  opacity: 0;
}

.color-option-title {
  vertical-align: top;
  margin-top: 0.2rem;
  padding-right: 0.5rem;
}

.back-btn {
  @apply border py-4 px-3;
}

.product {
  &__image {
    @apply flex;

    img {
      max-height: 38vh;

      @screen lg {
        @apply absolute block bottom-0 right-0 z-10;
        height: 500px;
        max-height: auto;
      }
    }
  }

  &__info {
    table {
      th {
        @apply bg-black text-white py-sm px-5 border-r border-white italic tracking-xs font-serif text-h6 leading-h5;
        min-width: 36px;

        &:first-child {
          @apply text-left font-normal font-sans not-italic;
        }
      }

      td {
        @apply py-sm px-5 border border-grey-19 tracking-sm leading-tight font-black text-xs;
        hyphens: auto;

        &:first-child {
          @apply tracking-sm font-normal leading-tight uppercase bg-grey-20;
          min-width: 140px;
        }
      }
    }
  }

  &__price {
    position: relative;

    &:after {
      content: '';
      width: 67px;
      height: 0;
      border-top: solid 1px;
      position: absolute;
      bottom: 16px;
      left: 90%;
      margin-left: -40px;
      opacity: 1;
      transform-origin: center center;
      transform: rotate(-45deg);
      transition: opacity 0.25s ease;
    }
  }

  &__tabs {
    background-image: url('/assets/pattern-tile.png');
    background-repeat: repeat;
    background-position: left top;

    &::v-deep {
      .tab {
        transition: none;
      }
    }

    &-container {
      @screen lg {
        max-width: 840px;
      }
    }
  }

  &__points-label {
    &:after {
      @apply border-t-6 border-b-6 border-l-6 border-solid;
      border-left-color: $color-black;
      border-bottom-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: -6px;
      transform: translate(0, -50%);
      z-index: 10;
    }
  }

  &__card {
    @screen lg {
      height: 580px;
      width: 660px;
    }

    &:after {
      @screen lg {
        @apply bg-black;
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: -68%;
        width: 300%;
        height: 1px;
        transform: rotate(-45deg);
      }
    }
  }

  &__desc {
    &::v-deep {
      p {
        @apply pb-3;
      }
      table {
        @apply border-collapse;
        width: auto !important;

        th {
          @apply bg-black border border-white text-white p-2;
          min-width: 36px;

          &:first-child {
            @apply font-bold text-left;
          }

          &:last-child {
            border-right-color: $color-black;
          }
        }

        td {
          @apply border border-grey-19 p-2;

          &:first-child {
            @apply bg-grey-20 uppercase;
          }
        }
      }
    }
  }

  &__qty {
    &::v-deep {
      .back-in-stock {
        & > p {
          @apply text-md text-left;
        }

        .modal-container-wrapper {
          @apply w-full;

          @screen sm {
            width: 660px;
          }
        }
      }
    }
  }
}
</style>
