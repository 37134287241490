<template>
  <div class="select-wrapper relative flex">
    <label
      v-if="hasDefaultSlot"
      class="lg:inline-block lg:align-middle label uppercase text-black text-h6 tracking-md leading-58px whitespace-no-wrap border border-solid px-xs text-center border-dark h-14 w-16 px-2 min-w-5"
    >
      <slot />
    </label>
    <div class="w-full">
      <select
        class="text-dark bg-transparent border-l-0 px-5 focus:outline-none border border-solid border-dark text-average tracking-xs w-full h-14 leading-none"
        :class="[{'has-error' : (isValid === false)},inputClass]"
        :name="name"
        :autocomplete="autocomplete"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @change="$emit('input', $event.target.value)"
        @input="$emit('input', $event.target.value)"
      >
        <option value="" disabled selected="selected" v-if="placeholder">
          {{ placeholder }}
        </option>
        <option
          v-for="(option, key) in options"
          :key="key"
          :value="option.value"
          v-bind="{selected: option.value === selected}"
        >
          {{ option.label }}
        </option>
      </select>
      <i class="ss-gizmo ss-navigatedown icon text-grey-17 text-2h6 leading-58px absolute top-0 pointer-events-none top-2px"></i>
    </div>
    <ValidationMessages v-if="validations" :validations="validations" />
  </div>
</template>

<script>
import ValidationMessages from './ValidationMessages'
import Validation from 'theme/mixins/forms/Validation'

export default {
  name: 'SwatchSelect',
  mixins: [Validation],
  components: {
    ValidationMessages
  },
  computed: {
    hasDefaultSlot () {
      return !!this.$slots.default
    }
  },
  props: {
    id: {
      type: String,
      required: false,
      default: ''
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    options: {
      type: Array,
      required: true,
      default: () => []
    },
    selected: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    autocomplete: {
      type: String,
      required: false,
      default: ''
    },
    inputClass: {
      type: String,
      required: false,
      default: ''
    },
    validations: {
      type: Array,
      default: () => []
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  $color-error: #e81212;

  .select-wrapper {
    select {
      border-radius: 0;
      padding-right: 53px;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -webkit-border-radius: 0;
      cursor: pointer;
    }
  }

  .has-error {
    border-bottom-width: 2px;
    border-bottom-color: $color-error;
  }

  .icon {
    right: 22px;
  }
</style>
