<template>
  <div>
    <div class="tabs mb-13">
      <ul class="tabs__list lg:flex justify-center relative">
        <li v-for="tab in tabs" class="tabs__item lg:border flex justify-center flex-col">
          <a href="javascript:void(0);" @click="selectTab(tab)" :class="{ 'tabs__link--active border-black border-6 lg:border-8': tab.isActive, 'border lg:border-0 lg:border-transparent': !tab.isActive }"
            class="tabs__link block font-bold leading-h1 tracking-lg uppercase text-center px-2">{{
            tab.name }}</a>
          <div class="tabs__content relative block lg:hidden py-sm">
            <transition name="slide-down">
              <div v-if="tab.isActive" class="tab text-sans text-above-md leading-sm" v-html="tab.text" />
            </transition>
          </div>
        </li>
      </ul>
    </div>
    <div class="tabs__content relative hidden lg:block">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProductTabs',
  data() {
    return {
      tabs: []
    }
  },
  created() {
    this.tabs = this.$children;
  },
  methods: {
    selectTab: function (selectedTab) {
      this.tabs.forEach(tab => {
        tab.isActive = (tab.name == selectedTab.name);
      });
    }
  }
}

</script>
<style lang="scss" scoped>
$color-black: #000;

  .slide-down-enter-active {
    transition: all 0.7s ease-in;
  }

  .slide-down-leave-active {
    transition: all 0.7s cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-down-enter-to,
  .slide-down-leave {
    max-height: 400px;
    overflow: hidden;
  }

  .slide-down-enter,
  .slide-down-leave-to {
    overflow: hidden;
    max-height: 0;
  }

.tabs {
  &__item {
    @screen lg {
      width: 325px;
    }
  }

  &__link {
    @apply relative;
    transition: border-color 0.5s ease;

    &:after {
      @apply absolute border-6 border-transparent left-50% border-solid;
      transition: opacity 0.5s ease;
      content: '';
      opacity: 0;
      top: calc(100% + 6px);
      margin-left: -6px;
      width: 0;
      height: 0;
      line-height: 0;
      border-top-color: $color-black;
      @screen lg {
        top: calc(100% + 8px);
      }
    }

    &--active {
      &:after {
        opacity: 1;
      }
    }
  }
}

</style>
