<template>
  <div class="bg-white">
    <h4 class="font-black uppercase text-xl xl:text-above-h2 leading-56px tracking-lg mb-15">
      <span>{{ $t('New Arrivals') }}</span>
    </h4>
    <div class="-mx-sm clearfix">
      <div
        class="w-1/3 px-sm mb-sm float-left"
        v-for="(asset, index) in brand.socialAssets"
        :key="index"
      >
        <a
          :href="asset.link"
          class="inline-block"
          target="_blank"
        >
          <img :src="asset.image" alt="Social Assets">
        </a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SocialAssets',
  props: {
    brand: {
      type: Object,
      required: true,
      default: () => {}
    }
  }
}
</script>
