<template>
  <button
    :class="['mr10 mb5 bg-cl-transparent brdr-1 brdr-circle brdr-cl-transparent :brdr-cl-bg-primary relative inline-flex pointer color', active ? 'active' : '']"
    @click="switchFilter(id, label)"
    @mouseover="$emit('hover', {id, label})"
    @mouseleave="$emit('hoverout', {id, label})"
    :aria-label="$t('Select color ') + label"
  >
    <span
      class="absolute brdr-circle brdr-1 brdr-cl-secondary block color-inside"
      :style="getColor"
    />
  </button>
</template>

<script>
import GenericSelector from '@vue-storefront/core/compatibility/components/GenericSelector'
import rootStore from '@vue-storefront/core/store'
import config from 'config'

export default {
  props: {
    products: {
      type: Array,
      required: false,
      default: () => []
    },
    groupIndex: {
      type: Number,
      required: true
    }
  },
  mixins: [GenericSelector],
  mounted () {
    if (this.groupIndex === 0) {
      this.switchFilter(this.id, this.label)
      this.$emit('setLabel', this.label)
    }
  },
  computed: {
    getColor () {
      let product = this.products.find((x) => String(x.shade || x.color) === String(this.id))

      if (product) {
        let result = (product.media_gallery || []).find((x) => x.lab === this.label)

        let image = this.getThumbnail(result || product.small_image || product.image, config.products.thumbnails.width, config.products.thumbnails.height)
        return `background-image: url('${image}'); background-color: #cccccc`
      }

      return this.colorFrom(this.label)
    }
  },
  methods: {
    colorFrom (label) {
      if (rootStore.state.config.products.colorMappings) {
        if (typeof rootStore.state.config.products.colorMappings[label] !== 'undefined') {
          label = rootStore.state.config.products.colorMappings[label]
        }
      }
      if (label.indexOf('/') >= 0) label = label.replace('/', ',') // to be honest - this is a hack for colors like "ink/white"
      if (label && label.toString().indexOf(',') >= 0) {
        return 'background: linear-gradient(' + label + ')'
      } else {
        return 'background-color: ' + label
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  $color-active: #000;

  .color {
    width: 30px;
    height: 30px;
    outline: none;
    margin-right: 0.1rem;

    &.active {
      .color-inside {
        border-color: $color-active;
      }
    }
  }

  .color-inside {
    width: 28px;
    height: 28px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    border-style: solid;
    border-width: 3px;
    border-color: transparent;

    &:after {
      content: '';
      border: 1px transparent solid;
      height: 100%;
      width: 100%;
      display: block;
      border-radius: 50%;
      z-index: -1;
    }
  }
</style>
