<template>
  <div class="container pb-30 pt-xl">
    <div class="-ml-sm -mr-sm clearfix">
      <div class="w-1/4 pl-sm pr-sm float-left">
        <nav class="navigation xl:pr-10">
          <div class="navigation__menu hover:bg-black relative mb-9.5">
            <div class="navigation__current relative cursor-pointer text-center px-md py-xl lg:px-xl">
              <i class="text-black icon icon-main brand" :class="`icon-${activeBrand} ${activeBrand}`"></i>
              <i class="ss-gizmo ss-navigatedown icon icon-navigatedown text-black absolute"></i>
            </div>
            <ul class="brands z-10 bg-black pt-8.5 pl-5 pb-7">
              <li
                v-for="(brand, index) in navigation"
                :key="index"
                @click="!currentLink(brand.id) ? selectBrand(brand.id, brand.link) : false"
                class="relative pl-16 py-sm"
                :class="!currentLink(brand.id) ? 'cursor-pointer' : 'cursor-default'"
              >
                <i class="text-white icon list-icon brand absolute" :class="`icon-${brand.listIcon}`"></i>
                <span class="font-bold text-h5 leading-loose tracking-lg uppercase text-grey-15 hover:text-white">
                  {{ brand.title }}
                </span>
              </li>
            </ul>
          </div>
          <ul class="navigation__list">
            <li v-for="(item, index) in activeMenu" :key="index" class="leading-h2">
              <router-link
                :to="localizedRoute(item.link)"
                class="navigation__link text-h5 leading-none tracking-lg text-black uppercase inline-block no-underline relative">
                {{ item.title }}
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
      <div class="w-3/4 pl-sm pr-sm float-left">
        <no-ssr>
          <component :is="this.$props.activeBlock" :brand="componentProps" />
        </no-ssr>
      </div>
    </div>
  </div>
</template>

<script>
import NoSSR from 'vue-no-ssr'
import MerchandisingSchematics from 'theme/components/theme/blocks/Materials/MerchandisingSchematics.vue'
import NewsPress from 'theme/components/theme/blocks/Materials/NewsPress.vue'
import ProductAssets from 'theme/components/theme/blocks/Materials/ProductAssets.vue'
import SocialAssets from 'theme/components/theme/blocks/Materials/SocialAssets.vue'

export default {
  name: 'StaticMaterials',
  props: {
    activeBlock: {
      type: String,
      default: 'MerchandisingSchematics'
    },
    activeBrand: {
      type: String,
      default: 'rco'
    }
  },
  data () {
    return {
      componentProps: {},
      activeMenu: [{
        title: this.$t('MERCHANDISING SCHEMATICS'),
        link: '/materials/r-and-co/merchandising-schematics'
      }, {
        title: this.$t('NEWS & PRESS'),
        link: '/materials/r-and-co/news-and-press'
      }, {
        title: this.$t('PRODUCT ASSETS'),
        link: '/materials/r-and-co/product-assets'
      }],
      navigation: [{
        title: this.$t('R+CO'),
        id: 'rco',
        listIcon: 'rco',
        link: '/materials/r-and-co/merchandising-schematics',
        schematicsLink: 'https://www.dropbox.com/sh/bdmkqb7ylw88tzn/AACHUTNmxTVLIdSqvmKZTm_wa?dl=0',
        schematicsImage: '/assets/Materials/LBP-Pro_RandCo_Merchandising.jpg',
        newsPressImages: [
          '/assets/Materials/press-rco-vicious.png',
          '/assets/Materials/press-rco-foil.png',
          '/assets/Materials/press-rcochiffn.png',
          '/assets/Materials/presss-rco-conformist.png',
          '/assets/Materials/press-rco-death-valley.png'
        ],
        productAssets: [{
          link: 'https://www.dropbox.com/sh/1wkhl7jagcrhmcw/AABa828vPsVvNvOUKLosEbd3a?dl=0',
          image: '/assets/Materials/LBP-Pro_RandCo_ProductAssets_FullSize.jpeg'
        }, {
          link: 'https://www.dropbox.com/sh/ki8d9cpo4iuw9q5/AAAGsarZ5oYGWZaegkb4Cy5Ia?dl=0',
          image: '/assets/Materials/LBP-Pro_RandCo_ProductAssets_Travel.jpeg'
        }, {
          link: 'https://www.dropbox.com/sh/bup2l16yxcv7r80/AADJOkY31FFZnAFDLpdOuBZza?dl=0',
          image: '/assets/Materials/LBP-Pro_RandCo_ProductAssets_Liters.jpeg'
        }],
        socialAssets: [{
          link: 'https://www.dropbox.com/sh/lorkf1rr34gr049/AACeuKYj_8Qf3wiu0mjYm1rVa?dl=0',
          image: '/assets/Materials/rco-march-2016.jpg'
        }],
        subNavigation: [{
          title: this.$t('MERCHANDISING SCHEMATICS'),
          link: '/materials/r-and-co/merchandising-schematics'
        }, {
          title: this.$t('PRODUCT ASSETS'),
          link: '/materials/r-and-co/product-assets'
        }]
      }, {
        title: this.$t('Smith & Cult'),
        id: 'smith-cult',
        listIcon: 'sc',
        link: '/materials/smith-and-cult/merchandising-schematics',
        schematicsLink: 'https://www.dropbox.com/sh/br3listddb40bhk/AABpxpbj-Ge1Cfr9pKUSOKuCa?dl=0',
        schematicsImage: '/assets/Materials/smith-cult-schematics.jpg',
        newsPressImages: [
          '/assets/Materials/sc-luckyshops.png',
          '/assets/Materials/sc-wmagazine-graduate.png',
          '/assets/Materials/sc-lucky-birdie-num-num.png',
          '/assets/Materials/sc-allure-kings.png',
          '/assets/Materials/sc-glamour-ghost.png',
          '/assets/Materials/sc-oprah-bridge.png',
          '/assets/Materials/sc-teen-vogue-shattered.png',
          '/assets/Materials/sc-zoe-report-caged.png'
        ],
        productAssets: [{
          link: 'https://www.dropbox.com/sh/dgsf1gonskp771f/AAD4-3YE6Lxw1K21FJO2UNQAa?dl=0',
          image: '/assets/Materials/sc-nailacquer.jpg'
        }],
        subNavigation: [{
          title: this.$t('MERCHANDISING SCHEMATICS'),
          link: '/materials/smith-and-cult/merchandising-schematics'
        }, {
          title: this.$t('NEWS & PRESS'),
          link: '/materials/smith-and-cult/news-and-press'
        }, {
          title: this.$t('PRODUCT ASSETS'),
          link: '/materials/smith-and-cult/product-assets'
        }]
      }, {
        title: this.$t('V76 by Vaughn'),
        id: 'vaughn',
        listIcon: 'v76',
        link: '/materials/v76/merchandising-schematics',
        schematicsLink: 'https://www.dropbox.com/sh/bhoj1wzt14jqh2b/AADaTxxr8WY54QT0UHfMKrFAa?dl=0',
        schematicsImage: '/assets/Materials/vaughn-schematics.jpg',
        newsPressImages: [
          '/assets/Materials/v76-eonline-brightng.png',
          '/assets/Materials/v76-askmen-lip-balm.png',
          '/assets/Materials/v76-entrpnr-product-line.png',
          '/assets/Materials/v76-maniac-spray.png',
          '/assets/Materials/v76-bustle-powder.png',
          '/assets/Materials/v76-paste-tex.png',
          '/assets/Materials/v76-tech-timesvratedwax.png',
          '/assets/Materials/v76_instyle-bright-sh-cond.png'
        ],
        productAssets: [{
          link: 'https://www.dropbox.com/sh/y4jb1lf3g7qed37/AABxLlqksdlC0y-obYzPQwL3a?dl=0',
          image: '/assets/Materials/hair-care-2.jpg'
        }, {
          link: 'https://www.dropbox.com/sh/0e249ewpm16fich/AACbDcRaJMbTiRBE8k3YppB6a?dl=0',
          image: '/assets/Materials/creams-gels.jpg'
        }, {
          link: 'https://www.dropbox.com/sh/xu3pkwzm702f2lp/AAAZ4EeYr_J6Sa8ySRiTV8qja?dl=0',
          image: '/assets/Materials/waxes-pomades.jpg'
        }, {
          link: 'https://www.dropbox.com/sh/ucvwbbjpysq5ryv/AADvK-rU3IXvgkfpVEReuohJa?dl=0',
          image: '/assets/Materials/shave.jpg'
        }, {
          link: 'https://www.dropbox.com/sh/0dvbj7licnlk8nv/AADAIsDisvFWYmEUs9hZb2h-a?dl=0',
          image: '/assets/Materials/mists-sprays.jpg'
        }, {
          link: 'https://www.dropbox.com/sh/4jtnsu4s1oyiw2f/AACbR3fk1lAWMDF5yGY0BvkLa?dl=0',
          image: '/assets/Materials/face-body.jpg'
        }],
        subNavigation: [{
          title: this.$t('MERCHANDISING SCHEMATICS'),
          link: '/materials/v76/merchandising-schematics'
        }, {
          title: this.$t('NEWS & PRESS'),
          link: '/materials/v76/news-and-press'
        }, {
          title: this.$t('PRODUCT ASSETS'),
          link: '/materials/v76/product-assets'
        }]
      }]
    }
  },
  components: {
    MerchandisingSchematics,
    NewsPress,
    ProductAssets,
    SocialAssets,
    'no-ssr': NoSSR
  },
  methods: {
    selectBrand(id, link) {
      this.$router.push(this.localizedRoute(link))
    },
    currentLink (id) {
      return id === this.activeBrand
    },
    initActiveBrand (activeBrand) {
      this.navigation.map(brand => {
        if (brand.id === activeBrand) {
          this.activeMenu = brand.subNavigation
          this.componentProps = {
            schematicsLink: brand.schematicsLink,
            schematicsImage: brand.schematicsImage,
            newsPressImages: brand.newsPressImages,
            productAssets: brand.productAssets,
            socialAssets: brand.socialAssets
          }
        }
      })
    }
  },
  watch: {
    activeBrand: function (newBrand) {
      this.initActiveBrand(newBrand)
    }
  },
  mounted () {
    this.initActiveBrand(this.activeBrand)
  }
}
</script>

<style lang="scss" scoped>
.brands {
  @apply absolute opacity-0;
  top: -9999px;
  transition: opacity .25s ease;
}

.navigation {
  &__menu {
    transition: background-color .25s ease;

    &:hover {
      .icon {
        @apply text-white;
      }

      .brands {
        @apply opacity-100;
        top: 100%;
        left: 0;
        right: 0;
      }
    }
  }
}

.ss-navigation {
  top: 10px;
  right: 10px;
}

.icon {
  &.icon-rco {
    @apply text-2h6;
  }

  &.icon-sc {
    @apply text-2md;
  }

  &.icon-v76 {
    @apply text-caption;
  }

  &.list-icon {
    &.icon-rco {
      left: 0;
      top: 12px;
    }

    &.icon-sc {
      left: -12px;
      top: 10px;
    }

    &.icon-v76 {
      left: 0;
      top: 8px;
    }
  }

  &.icon-main {
    &.rco {
      @apply text-big;
    }

    &.smith-cult {
      @apply text-average;
      @screen lg {
        @apply text-above-mid;
      }
    }

    &.vaughn {
      @apply text-average;
      @screen lg {
        @apply text-heading-page;
      }
    }
  }

  &.icon-navigatedown {
    top: 34px;
    right: 20px;
  }
}
</style>
