<template>
  <div class="bg-white">
    <h4 class="font-black uppercase text-xl xl:text-above-h2 leading-56px tracking-lg mb-15">
      <span>{{ $t('MERCHANDISING SCHEMATICS') }}</span>
    </h4>
    <a
      :href="brand.schematicsLink"
      class="inline-block"
      target="_blank"
    >
      <img :src="brand.schematicsImage" alt="Merchandising Schematics">
    </a>
  </div>
</template>

<script>

export default {
  name: 'MerchandisingSchematics',
  props: {
    brand: {
      type: Object,
      required: true,
      default: () => {}
    }
  }
}
</script>
