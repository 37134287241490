<template>
  <button
    :class="{'active': active}"
    class="bg-cl-primary brdr-1 brdr-cl-primary brdr-square h5 cl-tertiary generic-selector"
    @click="switchFilter(id, label)"
    :aria-label="$t('Select ' + label)"
  >
    {{ label }}
  </button>
</template>

<script>
import GenericSelector from '@vue-storefront/core/compatibility/components/GenericSelector'

export default {
  mixins: [GenericSelector]
}
</script>

<style lang="scss" scoped>
  $color-active: #000;
  $color-disabled: #cec;

  .generic-selector {
    height: 40px;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    min-width: 50px;
    background: #F1f1f1;
    border-color: #F1f1f1;

    &:hover,
    &:focus {
      border-width: 2px;
    }

    &.active {
      border-color: $color-active;
      border-width: 2px;
      color: #FFF;
      background: $color-active;
      font-weight: bold;
    }

    &:disabled {
      border-color: $color-disabled;
      color: $color-disabled;
      cursor: not-allowed;

      &:hover,
      &:after {
        border-width: 1px;
      }
    }
  }
</style>
